import React, { useState } from 'react';
import { getDatabase, ref, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import './firebase'; // Ensure this is correctly pointing to your Firebase.js file

function RegisterForm() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [address, setAddress] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const database = getDatabase();

    try {
      const userId = Date.now(); // Replace Firebase Auth ID with timestamp
      const userRef = ref(database, 'users/' + userId);

      await set(userRef, {
        fullName: fullName,
        email: email,
        contactNo: contactNo,
        address: address,
      });

      alert('User registered successfully');
      setTimeout(() => {
        navigate('/'); // Redirect to home page after 3 seconds
      }, 3000);
    } catch (error) {
      console.error('Failed to register user:', error);
      alert('Failed to register user: ' + error.message);
    }

    // Clear the form fields after submission
    setFullName('');
    setEmail('');
    setContactNo('');
    setAddress('');
  };

  return (
    <div className="w-full bg-gradient-to-r from-blue-300 to-indigo-300 py-4">
    <div className="bg-gradient-to-r from-white to-blue-300 p-8 rounded-lg max-w-md mx-auto shadow-lg mt-6">
      <h2 className="text-2xl font-bold text-center mb-6 text-gray-700">Request Call</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-600 font-semibold mb-2">Full Name:</label>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-semibold mb-2">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-semibold mb-2">Contact No:</label>
          <input
            type="text"
            value={contactNo}
            onChange={(e) => setContactNo(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-semibold mb-2">Address:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 focus:border-blue-500"
          />
        </div>
        <button
          type="submit"
          className="w-full py-3 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition duration-300 transform hover:scale-105"
        >
          Submit
        </button>
      </form>
    </div>
    </div>
  );
}

export default RegisterForm;
