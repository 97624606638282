import './firebase';
import { FaWhatsapp } from "react-icons/fa"; // Import FontAwesome WhatsApp icon
import React, { useState } from 'react';
import { getDatabase, ref, set } from 'firebase/database';


function ContactUs() {

  const[Name, setName]=useState('');
  const[mobileNo, setMobileNo]=useState('');
  const[email, setEmail]=useState('');
  const[message, setMessage]=useState('');
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const database=getDatabase();
  
    // Create a unique ID or use another unique identifier
    const userId = Date.now(); // Example of generating a unique ID
  
    // Create a reference to the new node in the database
    const userRef = ref(database, 'users2/' + userId);
  
    // Save the user data to Firebase
    try {
      await set(userRef, {
        Name: Name,
        mobile:mobileNo,
        email: email,
        message: message,
      });
    
      console.log('Data saved successfully');
      alert('Data saved successfully');
    } catch (error) {
      console.error('Failed to save data:', error);
      alert('Failed to save data');
    }
    setName('');
    setMobileNo('');
  setEmail('');
  setMessage('');
  };


  return (
    <section className="bg-gradient-to-r from-blue-300 to-gray-200  py-16">
       <a
        href="https://wa.me/943015717" // Replace with your WhatsApp number
        className="fixed top-80 z-10 right-5 bg-green-500 text-black p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={24} />
      </a>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900">Contact Us</h2>
          <p className="mt-4 text-lg text-gray-600">
            We're here to help and answer any question you might have. We look forward to hearing from you.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
          <div className="space-y-8">
            <div className="bg-white p-8 rounded-lg shadow-lg hover:scale-105 duration-500">
              <h3 className="text-xl font-semibold text-gray-700">Get in Touch with</h3>
              <h3 className="text-xl font-bold text-black">SWARD GROUP OF TECHNOLOGY</h3>
              <p className="mt-4 text-gray-600">You can reach out to us via phone or email.</p>
              <div className="mt-6 space-y-4">
                <div className="flex items-center text-gray-600">
                  <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26c.32.21.74.21 1.06 0L21 8m-6 8H9a2 2 0 01-2-2V6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2z"></path>
                  </svg>
                  <span>Email: care@swardgroup.in</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <svg className="h-6 w-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h7m-7 4h6m-6 4h3m10-8h3m-3 4h2m-2 4h1"></path>
                  </svg>
                  <span>Phone: +91 94711 07618</span>
                </div>
              </div>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg hover:scale-105 duration-500">
              <h3 className="text-xl font-semibold text-gray-900">Our Location</h3>
              <p className="mt-4 text-gray-600">Visit us at our headquarters for a face-to-face discussion.</p>
              <div className="mt-6 text-gray-600">
                <svg className="h-6 w-6 mr-3 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10l6-6m0 0l6 6M9 20V6"></path>
                </svg>
                <span className="font-bold">Sasanberia, Nirsa, Dhanbad-828205</span>
                <h3 className="text-xl font-extrabold">SWARD GROUP OF TECHNOLOGY</h3>
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-gray-900">Send Us a Message</h3>
            <form onSubmit={handleSubmit} className="mt-8 space-y-6">
              <div>
                <label htmlFor="name"  className="block text-sm font-medium text-gray-700">Name</label>
                <input type="text" onChange={(e) => setName(e.target.value)}
            required value={Name} id="name" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" placeholder="Full-Name" />
              </div>

              <div>
                <label htmlFor="mobile"  className="block text-sm font-medium text-gray-700">Contact No.</label>
                <input type="number" onChange={(e) => setMobileNo(e.target.value)}
            required value={mobileNo} id="name" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" placeholder="Mobile no." />
              </div>

              <div>
                <label htmlFor="email"  className="block text-sm font-medium text-gray-700">Email</label>
                <input type="email" onChange={(e) => setEmail(e.target.value)}
            required value={email} id="email" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" placeholder="name123@gmail.com" />
              </div>
              <div>
                <label htmlFor="message"  className="block text-sm font-medium text-gray-700">Message</label>
                <textarea id="message" onChange={(e) => setMessage(e.target.value)}
            required value={message} rows="4" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" placeholder="Mention requirements regarding your desired website"></textarea>
              </div>
              <div>
               <button type="submit" className="sendDescriptionButton w-full bg-blue-400 text-white py-3 px-4 rounded-md shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500" >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
