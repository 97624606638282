import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/logo2.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false); // Toggle state for small screens

  return (
    <nav className="w-full flex justify-between items-center p-2 bg-gradient-to-r from-blue-400 to-indigo-400 sticky top-0 z-10">
      {/* Logo */}
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-20 rounded-full" />
        </Link>
      </div>

      <div className="requestCall hidden max-md:w-1/3 max-md:flex">
      <Link to="/register" className="hidden border-black bg-blue-600 text-black text-lg max-md:text-sm p-2 rounded-lg font-bold bookDemo max-md:block">Request a Call</Link>
      </div>

      {/* Toggle Button for Small Screens */}
      <div className="max-md:flex flex-col cursor-pointer md:hidden" onClick={() => setIsOpen(!isOpen)
        
      }>
        <div className="w-8 h-1 bg-black mb-1"></div>
        <div className="w-8 h-1 bg-black mb-1"></div>
        <div className="w-8 h-1 bg-black"></div>
      </div>

      {/* Navbar Links */}
      <ul
        className={`md:flex md:items-center gap-6 max-md:gap-2 max-md:py-6 max-md:absolute max-md:top-2 max-md:pl-2 max-md:text-sm max-md:right-12 max-md:bg-blue-300 max-md:rounded-2xl md:bg-transparent md:static transition-transform ${
          isOpen ? 'inline-flex' : 'hidden'
        }`}
      >
        <li>
          <Link
            to="/"
            className="navbarLinks max-md:bg-white max-md:text-sm block p-2 md:border-2 md:border-black md:text-black rounded-xl text-lg transition-all hover:bg-aliceblue hover:scale-105"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/services"
            className="navbarLinks block max-md:bg-white p-2 max-md:text-sm md:border-2 md:border-black md:text-black rounded-xl text-lg transition-all hover:bg-aliceblue hover:scale-105"
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className="navbarLinks max-md:bg-white max-md:text-sm block p-2 md:border-2 md:border-black md:text-black rounded-xl text-lg transition-all hover:bg-aliceblue hover:scale-105"
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            className="navbarLinks max-md:bg-white max-md:text-sm block p-2 md:border-2 md:border-black md:text-black rounded-xl text-lg transition-all hover:bg-aliceblue hover:scale-105"
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* Log Button */}
      <ul className="md:flex items-center max-md:hidden">
        <li>
          <Link
            to="/register"
            className="bg-blue-400 text-black block bookDemo text-xl font-semibold px-4 py-4 rounded-xl border-2 border-black max-md:w-full"
          >
            Request a Call
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
