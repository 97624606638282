import React from "react";
import Servicecard from './Servicecard.js';
import webDesign from "./assets/webdesign.png";
import graphicsDesign from "./assets/graphicsdesign.png";
import digitalMarketing from "./assets/digitalmarketing.png";
import logoDesign from "./assets/logodesign2.png";
import otherImage from "./assets/otherImage.png";
import appDevelopment from "./assets/appdevelopment.png";
import { FaWhatsapp } from "react-icons/fa"; // Import FontAwesome WhatsApp icon

function Services() {
  return (
    <div className="bg-gray-100 mb-8 py-4">
      <a
        href="https://wa.me/943015717" // Replace with your WhatsApp number
        className="fixed max-md:right-0 top-80 z-10 right-5 bg-green-500 text-black p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={24} />
      </a>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900">Our Services</h2>
          <p className="mt-4 text-lg text-gray-600">
            We offer a range of services to help your business thrive in the digital world.
          </p>
        </div>
      </div>

      {/* Horizontal Scrollable Container */}
      <div className="overflow-x-auto py-4 px-4">
        <div className="flex mx-4 min-w-max">
          <Servicecard 
            image={webDesign} 
            serviceName="Web Designing" 
            serviceDescription="We design attractive and performant websites for you" 
            serviceTool1="#HTML" 
            serviceTool2="#Tailwind CSS" 
            serviceTool3="#Javascript" 
          />
          <Servicecard 
            image={graphicsDesign} 
            serviceName="Graphics Designing" 
            serviceDescription="We provide awesome graphics to clients" 
            serviceTool1="#CorelDraw" 
            serviceTool2="#Photoshop" 
            serviceTool3="#Canva"
          />
          <Servicecard 
            image={logoDesign} 
            serviceName="Logo Designing" 
            serviceDescription="You get your desired best suite logo" 
            serviceTool1="#Adobe Illustrator" 
            serviceTool2="#Photoshop" 
            serviceTool3="#Photoshop Lightroom"
          />
          <Servicecard 
            image={digitalMarketing} 
            serviceName="Digital Marketing" 
            serviceDescription="We help and support you to build and grow your business" 
            serviceTool1="#Strategies" 
            serviceTool2="#Networking" 
            serviceTool3="#Milestones"
          />
          <Servicecard 
            image={appDevelopment} 
            serviceName="App Development" 
            serviceDescription="We provide quality applications for your business" 
            serviceTool1="#Java" 
            serviceTool2="#Kotlin" 
            serviceTool3="#Android Studio"
          />
          <Servicecard 
            image={otherImage} 
            serviceName="Other Services" 
            serviceDescription="To checkout our other services, visit our contact us page" 
            serviceTool1="#Education" 
            serviceTool2="#Courses" 
            serviceTool3="#Consultancy" 
          />
        </div>
      </div>
    </div>
  );
}

export default Services;
