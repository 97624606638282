import {React} from "react";
import { useNavigate } from "react-router-dom";
import { ArrowUpRight } from "lucide-react";

function Servicecard({ serviceName, serviceDescription, image, serviceTool1, serviceTool2, serviceTool3 }) {

  
    const navigate = useNavigate(); // Initialize the useNavigate hook
  
    const handleContactRedirect = () => {
      navigate('/contact'); // Navigate to the contact page
    };
  return (
    <div className="flex-shrink-0 w-[240px] rounded-l border mr-4 mb-4 bg-white shadow-lg hover:scale-105 duration-500">
      <img
        src={image}
        alt="Service"
        className="w-full h-56 object-cover rounded-t-md"
      />
      <div className="p-4">
        <h1 className="inline-flex items-center text-lg font-semibold">
          {serviceName} <ArrowUpRight className="h-4 w-4 ml-1" />
        </h1>
        <p className="mt-3 text-sm text-gray-600">
          {serviceDescription}
        </p>
        <div className="mt-4 flex flex-wrap gap-2">
          <span className="inline-block rounded-full bg-gray-100 px-3 py-1 text-[10px] font-semibold text-gray-900">
            {serviceTool1}
          </span>
          <span className="inline-block rounded-full bg-gray-100 px-3 py-1 text-[10px] font-semibold text-gray-900">
            {serviceTool2}
          </span>
          <span className="inline-block rounded-full bg-gray-100 px-3 py-1 text-[10px] font-semibold text-gray-900">
            {serviceTool3}
          </span>
        </div>
        <button onClick={handleContactRedirect}
          type="button"
          className="servicesButton mt-6 w-full rounded-2xl bg-black py-1.5 text-m font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
        >
       View
        </button>
      </div>
    </div>
  );
}

export default Servicecard;
