import React from "react";
import { FaWhatsapp } from 'react-icons/fa';

function About() {
  return (
    <div className="bg-gradient-to-r from-blue-300 to-gray-200 py-16 px-6 sm:px-8 lg:px-12">
           <a
        href="https://wa.me/943015717" // Replace with your WhatsApp number
        className="fixed top-80 z-10 right-5 bg-green-500 text-black p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={24} />
      </a>

      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800 sm:text-5xl">
            About Us
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            Learn more about our mission, values, and the team behind our
            success.
          </p>
        </div>

        {/* Mission Section */}
        <div className="bg-white p-8 rounded-lg shadow-lg mb-12 hover:scale-105 duration-500">
          <h2 className="text-3xl font-semibold text-gray-800">Our Mission</h2>
          <p className="mt-4 text-gray-600">
            Our mission is to empower businesses and individuals by creating
            innovative, user-centric websites that drive engagement and deliver
            measurable results. I am committed to offering personalized web
            development solutions that blend creativity, functionality, and
            performance, ensuring each project reflects the unique needs of my
            clients. With a focus on quality, transparency, and ongoing
            collaboration, I aim to help my clients grow their online presence
            and achieve their digital goals efficiently and effectively.
          </p>
        </div>

        {/* Values Section */}
        <div className="flex flex-wrap -mx-4 mb-12">
          <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
            <div className="bg-white p-8 rounded-lg shadow-lg hover:scale-105 duration-500">
              <h3 className="text-2xl font-semibold text-gray-800">Quality</h3>
              <p className="mt-4 text-gray-600">
                We believe in delivering exceptional quality in every project we
                undertake. By adhering to best practices and focusing on the
                smallest details, we ensure that the websites we create are not
                only visually appealing but also highly functional, secure, and
                optimized for performance. Quality is at the heart of everything
                we do, we take pride in building long-lasting digital
                solutions that stand the test of time.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
            <div className="bg-white p-8 rounded-lg shadow-lg hover:scale-105 duration-500">
              <h3 className="text-2xl font-semibold text-gray-800">
                Inclusivity
              </h3>
              <p className="mt-4 text-gray-600">
                Inclusivity is a core principle in our work. We strive to create
                websites that are accessible to everyone, regardless of their
                abilities or background. By adhering to web accessibility
                standards and considering diverse user needs, we ensure that our
                designs and interfaces are user-friendly, responsive, and
                welcoming to all. Our goal is to build digital spaces where
                everyone feels included and empowered.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4">
            <div className="bg-white p-8 rounded-lg shadow-lg hover:scale-105 duration-500">
              <h3 className="text-2xl font-semibold text-gray-800">
                Innovation
              </h3>
              <p className="mt-4 text-gray-600">
                Innovation drives our approach to web development. We continuously
                explore new technologies, frameworks, and creative solutions to
                stay ahead of the curve and offer cutting-edge services to our
                clients. Whether it’s implementing the latest design trends or
                using modern development tools, we are committed to pushing the
                boundaries of what’s possible and delivering forward-thinking
                websites that give our clients a competitive edge."
              </p>
            </div>
          </div>
        </div>

        {/* Team Section */}
        <div className="text-center mb-8">
          <h2 className="text-4xl font-extrabold text-gray-800">
            Meet Our Team
          </h2>
          <p className="mt-4 text-xl font-semibold text-gray-600">
            Our passionate team members are dedicated to your success.
          </p>
        </div>
        <div className="flex flex-wrap -mx-4">

        <div className="w-full sm:w-1/2 md:w-1/4 px-4 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-lg hover:scale-105 duration-500">
              <img
                src="https://via.placeholder.com/150"
                alt="Team Member"
                className="w-32 h-32 mx-auto rounded-full mb-4"
              />
              <h4 className="text-xl font-semibold text-gray-800">
                Prakash Kumar
              </h4>
              <p className="text-gray-600">Lead Developer</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 px-4 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-lg hover:scale-105 duration-500">
              <img
                src="https://via.placeholder.com/150"
                alt="Team Member"
                className="w-32 h-32 mx-auto rounded-full mb-4"
              />
              <h4 className="text-xl font-semibold text-gray-800">Rahul Kumar Banerjee</h4>
              <p className="text-gray-600">CEO & Founder</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 px-4 mb-8 ">
            <div className="bg-white p-6 rounded-lg shadow-lg hover:scale-105 duration-500">
              <img
                src="https://via.placeholder.com/150"
                alt="Team Member"
                className="w-32 h-32 mx-auto rounded-full mb-4"
              />
              <h4 className="text-xl font-semibold text-gray-800">
                Shabahat Parveen
              </h4>
              <p className="text-gray-600">Graphics Designer</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 px-4 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-lg hover:scale-105 duration-500">
              <img
                src="https://via.placeholder.com/150"
                alt="Team Member"
                className="w-32 h-32 mx-auto rounded-full mb-4"
              />
              <h4 className="text-xl font-semibold text-gray-800">
                Abul Farah Ansari
              </h4>
              <p className="text-gray-600">Marketing Head</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
