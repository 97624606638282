import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from bg-blue-500 to-indigo-400 text-white px-12 py-2">
      <div className="container mx-auto flex justify-between items-center flex-wrap max-md:flex-col max-md:text-center">
        
        {/* Address Section */}
        <div className="w-1/3 max-md:w-full max-md:mb-4">
          <h3 className="text-xl font-extrabold">Address</h3>
          <p className="text-lg mt-2">Sasanberia, Nirsa, Dhanbad-828205</p>
          <p className="text-lg">Phone: +91 94711 07618</p>
          <p className="text-lg">Email: care@swardgroup.in</p>
        </div>

        {/* Terms & Policies Section */}
        <div className="w-1/3 mb-9 max-md:w-full max-md:mb-4">
          <h3 className="text-xl top-0 font-extrabold">Terms & Policies</h3>
          <div className="flex w-40 max-md:w-full flex-col max-md:items-center">
            <a href="/contact" className="text-lg text-black hover:text-white hover:rounded-lg hover:bg-blue-300 pl-6 hover:scale-105 duration-500">
              Privacy Policy
            </a>
            <a href="/contact" className="text-lg text-black hover:text-white hover:rounded-lg hover:bg-blue-300 pl-6 hover:scale-105 duration-500">
              Terms of Service
            </a>
          </div>
        </div>
        {/* Copyright Section */}
        <div className="w-1/3 bottom-0  max-md:w-full">
        <p className="text-white text-xl font-extrabold mt-2 max-md:mt-2 "> 
          Built with ♥ by Sward Group.
        </p>
          <p className="text-lg font-extrabold mt-2 max-md:mt-2">
            © {new Date().getFullYear()} Sward Group. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
